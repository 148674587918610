.container {
  margin-top: 20px;
  z-index: 100;
  position: relative;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  .container {
    max-width: 730px;
  }
}

.container .panel {
  z-index: 100;
}

.container .panel-jumbotron {
  border: solid 1px black;
}

.container .panel-jumbotron h1 {
  margin-bottom: 20px;
}

.container .panel-jumbotron .panel-footer i {
  vertical-align: middle;
  line-height: 48px;
  color: black;
}

.container .panel-jumbotron .btn:hover {
  opacity: 0.7;
}

.container .panel-jumbotron .pointer {
  cursor: pointer;
}

.social-link {
    margin-left: 12px;
}
